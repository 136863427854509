<template>
  <header>
    <vueper-slides show-multiple-slides :dragging-distance="70" prevent-y-scroll fixed-height="300px" autoplay>
      <vueper-slide
        v-for="(slide, i) in slides"
        :key="i"
        :image="baseUrl + slide.url"
      />
    </vueper-slides>
  </header>
</template>
<script>
//Vuejs slides
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import ApiClient from '@/services/axios'
import { ref } from 'vue'
export default {
  name: 'LandingPageHeader',
  components: { VueperSlides, VueperSlide },
  setup() {
    const baseUrl = ref(process.env.VUE_APP_API_URL)
    const slides = ref([{ }])
    ApiClient.post('/slider/filter', {
      query: {status:'active'},
    })
      .then((res) => {

        //order function to sort slide images 
        res.data.sort(function(x, y) {
        return (x.order === y.order )? 0 : x.order<y.order? -1 : 1;
    });
        slides.value = res.data
      })
      .catch((err) => console.log(err))
    return {
      slides,
      baseUrl,
    }
  },
}
</script>
<style scoped>
header {
  background-color: #eee;
  height: 40vh;
  min-height: 21.4rem;
  width: 100%;
  overflow: hidden;
}
header .overlay {
  height: 100%;
  width: 100%;
  background-color: #eee;
  opacity: 0.5;
}
</style>